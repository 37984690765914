<template>
  <!-- 关键词 文章列表 -->
  <div class="container" @mousemove.prevent="mousemove" @mouseup.prevent="imgListMouseupcoor">
    <!-- 导航 -->
    <V-ArticleNav class="headClass" :navList="navList"></V-ArticleNav>
    <div class="gWbc title" v-if="taskInfo">{{taskInfo.taskname}}</div>
    <div class="gWbc basicInfo">
      <div class="left_img_b">
        <i class="sx_btn el-icon-arrow-left" @click="tapImgNum(-1)" style="margin-right:20px"></i>
        <div class="left_img" @mousewheel.prevent="handleScroll" ref="imgTop">
          <img :src="api.LoginURL.concat('/resources/trading/upload'+imgList[imgNum])" ref="imgTitle" :class="{imgYd:mouseInDiv}"
          :style="{width:clientWidth/100*imgWidth+'px',top:top+'px',left:left+'px'}" v-if="imgList[imgNum]"
          @mousedown.prevent="getMousedowncoor" @load="imgLoad"> 
        </div>
        <i class="sx_btn el-icon-arrow-right" @click="tapImgNum(1)" style="margin-left:20px"></i>
      </div>
      <div class="imgControl">
        <div class="controlList">
          图片列表
          <el-switch v-model="imgListShow" style="margin-left:10px" active-color="#13ce66"
           inactive-color="#ff4949"></el-switch>
        </div>
        <div class="controlZomm">
          <div class="jj_btn" @click="tapJJ('+')">+</div>
          <span class="jj_bum">{{imgWidth}}%</span>
          <div class="jj_btn" @click="tapJJ('-')">-</div>
        </div>
        <div class="controlText" style="max-width:300px;">
          {{showFileName(imgList[imgNum])}}
        </div>
        <div class="controlText">
          图片总数：{{imgList.length}}张
        </div>
        <div class="controlText" style="cursor: pointer;border-right-width:0;color:#0084FF" @click="imgQualityFeedback">
          图片质量反馈
        </div>
      </div>
      <div class="imgPreviewList" v-show="imgListShow" @mousedown.prevent="imgListMousedowncoor">
        <div class="imgYcGdt" ref="imgList">
          <div class="imgDiv" ref="imgListDiv">
            <img v-for="(url,i) of imgList" :key="i" :src="api.LoginURL.concat('/resources/trading/upload'+url)"
             class="previewList" :class="{imgBorder:i==imgNum}" @mouseup.prevent="tapSelImg(i)">
          </div>
        </div>
      </div>
      
      <div class="getInfo" v-for="(item,i) of subtaskList" :key="i">
        <el-button type="primary" v-show="subtaskList.length>1" class="removeTask" icon="el-icon-minus"
           @click="tapRemoveSubtask(i)"></el-button>
        <div class="infoItem">
          <div class="item_title">
            <span>*</span>子任务名称名：
          </div>
          <div class="info_inout">
            <el-input type="text" v-model="item.childTaskName" class="input" @input="(e)=>{textLength(e,item)}"
              @blur="(e)=>{textRepetition(e,item)}" placeholder="请输入子任务名称名(30个字)"></el-input>
          </div>
        </div>
        <div class="infoItem">
          <div class="item_title">
            <span>*</span>{{taskInfo&&taskInfo.isPaging==1?'子任务分页':'页码填写'}}：
          </div>
          <div class="info_inout">
            <el-input type="text" v-model="item.startPage" class="input_num" disabled></el-input>
            <span style="margin-left:10px;">页</span>
            <div style="margin-left:10px;" class="input_hx"></div>
            <el-input type="text" v-model="item.endPage" class="input_num" style="margin-left:10px;" @blur="e=>{subtaskInputNum(e,i)}"
              @input="(e)=>{item.endPage=e.replace(/[^\d]/g,'')}"></el-input>
            <span style="margin-left:10px;">页</span>
          </div>
        </div>
        <div class="infoItem getInfoTs">
          提示：填写图片序号<span v-if="taskInfo&&taskInfo.isPaging==1">，加工者设置了需要根据图片内容来进行分页操作，请仔细观看图片内容</span>
        </div>
        <div class="infoItem">
          <div class="item_title">
            <span>*</span>加工酬劳设置：
          </div>
          <div class="info_inout">
            <el-input type="text" v-model="item.childTaskMoney" class="input" placeholder="请输入正整数"
             @input="(e)=>{item.childTaskMoney=e.replace(/[^\d]/g,'')}"></el-input>
            <span>知识币</span>
          </div>
        </div>
        <div class="infoItem">
          <div class="item_title">
            <span>*</span>加工工期设置：
          </div>
          <div class="info_inout">
            <el-input type="text" v-model="item.childTaskDay" class="input" placeholder="请输入正整数"
             @input="(e)=>{item.childTaskDay=e.replace(/[^\d]/g,'')}"></el-input>
            <span>天</span>
          </div>
        </div>
        
      </div>

      <div class="addInfoBtn">
        <div class="addBtn">
          <span>点击”+“号可以增加子任务设置栏目,点击”-“可以移除子任务。</span>
          <el-button type="primary" icon="el-icon-plus" @click="tapAddSubtask"></el-button>
        </div>
        <div class="addBtns">
          <el-button type="info" @click="$router.go(-1)">取消</el-button>
          <el-button type="primary" style="margin-left:200px;" @click="tapAccomplish">完成</el-button>
        </div>
      </div>
    </div>

    <div class="keepoOut" v-show="qualityShow">
      <div class="keepDiv">
        <div class="keepTitle">
          <span>图片质量反馈</span>
          <div class="keepBack">
            <i class="el-icon-close" @click="qualityShow=false"></i>
          </div>
        </div>
        <div class="keepinput">
          <div class="infoItem areaStyle1">
            <div class="item_title">
              图片编号：
            </div>
            <div class="info_inout">
              <div class="input">{{showFileName(imgUrl)}}</div>
            </div>
          </div>
          <div class="infoItem areaStyle">
            <div class="item_title">
              <span>*</span>质量问题：
            </div>
            <div class="info_inout">
              <el-input type="textarea" v-model="qualityFeedback" class="input" placeholder="质量问题" resize="none"></el-input>
            </div>
          </div>
          <div class="infoItem">
            <el-button type="primary" @click="tapImgFeedback">确认</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
import ArticleNav from "@/components/article-nav/index.vue";
import { QUERYED,UPDATES,INSERT,DELETE } from "@/services/dao.js";
import { Loading } from 'element-ui'
export default {
  components: {
    "V-ArticleNav": ArticleNav
  }, 
  data() {
    return {
      navList:[  //导航 
        {url:'/taskDetail',word:''},
        {word:'任务划分'}
      ],
      taskInfo:null,  //任务详情
      id:'', 
      //控制图片的默认值
      imgList:[],  //图片列表
      imgNum:0,  //图片下标
      clientWidth:1,  //图片初始宽度
      imgWidth:70,  //图片缩放百分比
      mouseInDiv:false,  //是否可拖动
      top:0,  //图片位移
      left:0, //图片位移
      clientX:0, //鼠标位置
      clientY:0,   //鼠标位置
      offsetX:0,  //鼠标距离容器顶部的距离
      offsetY:0,  //鼠标距离容器左边的距离   

      // 图片列表控制
      imgListShow:false,  //是否显示图片缩略图
      isImgMouse:false,  //是否可拖动
      imgScrollLeft:0,  //拖动距离
      lastDist:0,  //上一次距离
      statejl:0,  //鼠标开始距离
      endjl:0,  //鼠标抬起后的距离

      //图片质量反馈
      qualityShow:false,  //是否显示质量反馈
      imgUrl:'',   //反馈图片路径
      qualityFeedback:'',   //问题反馈

      userInfo:null,  //用户信息

      //子任务
      subtaskList:[],  //子任务划分列表
      subtask:{  //子任务数据
        taskType:1,  //主任务分类  1
        taskId:'',   //主任务Id
        taskName:'',  //主任务名称
        fzrId:'',  //负责人id
        fzrName:'',  //负责人名称
        childTaskName:'',  //子任务名称
        childTaskDescribe:'',  //子任务描述
        childTaskPaging:'',  //分页 0-1
        childImgpath:'',   // 子任务加工图片路径
        childTaskMoney:'',  //子任务酬劳
        childTaskDay:'',  //子任务工期
        status:0,  //子任务状态   默认状态
        startPage:'',  //分页开始
        endPage:'',   //分页结束
      },
      LoadingIcon:null,  //加载
    }
  },
  created(){
    let {id,type} = this.$route.query
    let userid = this.$store.state.userinfo.id;  
    let url = this.navList[0].url
    this.id = id
    this.$set(this.navList[0],'url',url+'?id='+id+'&type='+type) 

    // if(!userid){
    //   this.$alert('请登录!', '提示', {
    //     confirmButtonText: '确定',
    //     callback: () => {
    //       this.$router.push({path:'/login'}) 
    //     }
    //   });
    //   return
    // }
    
    this.getUserInfo(userid)
    
  },
  mounted(){
    this.$nextTick(()=>{
      this.clientWidth =  this.$refs.imgTop.clientWidth
    })
  },
  destroyed(){
    this.$root.Hub.$emit('releaseSuccess')
  },
  methods: {
    //获取去用户信息
    async getUserInfo(id){
      let res = await QUERYED(
        "post","",
        "User(where:{id: {_eq:"+ id +"}}) { id name }"
      );
      if(res.success){
        this.userInfo = res.data.User[0]
        this.subtask.fzrId = res.data.User[0].id
        this.subtask.fzrName = res.data.User[0].name
        this.subtaskList.push(JSON.parse(JSON.stringify(this.subtask)))
        this.$set(this.subtaskList[0],'startPage',1)
        this.getData(this.id)
      }else{
        this.msgWarn('获取用户数据失败')
      }
    },

    //分页设置
    subtaskInputNum(e,i){
      let {msgWarn,subtaskList,imgList} = this
      let num = e.target.value*1
      if(!num)return
      let startPage = subtaskList[i].startPage
      if(i>0){
        let endPage = subtaskList[i-1].endPage
        if(!endPage){
          msgWarn('上一个的子任务的分页未设置')
          num = ''
        }
      }
      if(num<startPage){
        msgWarn('输入结束页不能小于开始页')
        num = ''
      }
      if(num>=imgList.length){
        if(num>imgList.length)msgWarn('结束页最大设置为'+(imgList.length)) 
        num = imgList.length
        subtaskList.splice(i+1)
      }
      this.$set(subtaskList[i],'endPage',num)
      this.$set(subtaskList[i],'childTaskPaging',startPage+'-'+num)
      this.$set(subtaskList[i],'childImgpath',imgList.slice(startPage-1,num)+'')
      if(i<(subtaskList.length-1)){
        for(let t in subtaskList){
          if(t>i){
            this.$set(subtaskList[t],'startPage','')
            this.$set(subtaskList[t],'endPage','')
            this.$set(subtaskList[i],'childTaskPaging','')
          }
        }
        subtaskList[i+1].startPage = num?(num+1):''
      }
    },

    //添加子任务
    tapAddSubtask(){
      if((this.subtaskList.length)>=(this.imgList.length)){
        this.msgWarn('最多添加'+this.imgList.length+'个子任务')
        return
      }
      let num = this.subtaskList[this.subtaskList.length-1].endPage
      let obj = JSON.parse(JSON.stringify(this.subtask))
      obj.startPage = num?(num+1):''
      this.subtaskList.push(obj)  
    }, 

    //移除子任务
    tapRemoveSubtask(i){
      let {subtaskList} = this
      subtaskList.splice(i,1)
      for(let t in subtaskList){
        if(i<=t){
          this.$set(subtaskList[t],'startPage','')
          this.$set(subtaskList[t],'endPage','')
          this.$set(subtaskList[i],'childTaskPaging','')
        }
      }
      if(i==0){
        this.$set(subtaskList[0],'startPage',1)
      }else if(i<(subtaskList.length)){
        this.$set(subtaskList[i],'startPage',subtaskList[i-1].endPage+1)
      }
    },

    //限制字数
    textLength(e,item){
      if(e.length>30){
        this.msgWarn('最大长度为30个字')
        this.$set(item,'childTaskName',e.slice(0,30))
      }
    },


    //查看任务名称是否重复
    async textRepetition(e,item){
      let list = this.subtaskList
      let value = this.pdIsEmpty(e.target.value)
      if(value){
        let num = 0
        for(let obj of list){
          if(obj.childTaskName==value){
            num++
          }
        }
        if(num>=2)this.msgWarn('划分子任务名称已存在')
        this.$set(item,'isExist',num>=2)
        // let res = await QUERYED(
        //   "post","",
        //   "jgChildTask_aggregate(where:{childTaskName: {_eq:\""+ value +"\"}}) { aggregate {count}}"
        // );
        // if(res.success){
        //   let isExist = res.data.jgChildTask_aggregate.aggregate.count>0
        //   this.$set(item,'isExist',isExist)
        //   if(isExist){
        //     this.msgWarn('当前子任务名称已存在')
        //   }
        // }
      }
    },

    //获取任务详情
    async getData(id){
      let tableStr ="jgPeper(where:{id:{_eq:"+id+"}}) {"
        +"id taskname userid username describe type zipPath imgPath resultformat isPaging isFullTextEntry checkWay processingFiles"
        +" customEntry errorRate totaldays userCreditLevel totalmoney fzr_totalmoney jgFzrAndTaskId status issueTime tasktype}"
      let res = await QUERYED(
        "post","",tableStr
      );
      if(res.success){
        this.taskInfo = res.data.jgPeper[0]
        let {imgPath,childImgpath,childTaskPaging,taskId,childTaskId} =this.taskInfo
        this.imgList = imgPath?imgPath.split(','):[]
        // this.getCustomEntry(taskId)
        // this.typeInfo.jgPeperId = taskId
        // this.typeInfo.childTaskId = childTaskId
        // this.typeInfo.imgUrl = this.imgList[0]
        // this.getJgTypeIn(taskId,childTaskId)
      }
    },

    //警告弹窗
    msgWarn(message,type){
      this.$message({
        message,type: type||'warning'
      });
    },
    //判断值是否为空
    pdIsEmpty(e){
      return e.replace(/ /g,'')
    },

    //获取图片或文件名字
    showFileName(text){
      if(text){
        let list = text?text.split('/'):[]
        return list[list.length-1]
      }
    },

    //图片上一页、下一页
    tapImgNum(num){
      let min = 0
      let max = this.imgList.length -1
      let imgNum = this.imgNum
      imgNum += num*1
      if(imgNum<min){
        this.msgWarn('前面已经没有了')
        return        
      }
      if(imgNum>max){
        this.msgWarn('后面已经没有了')
        return        
      }
      this.imgNum = imgNum
      this.left = 0 //图片位移
      this.top = 0   //图片位移
      this.imgWidth = 70
    },
    
    //图片加载时
    imgLoad(e){
      let h = this.$refs.imgTop.clientHeight
      let w = this.$refs.imgTop.clientWidth
      let h1 = e.target.clientHeight
      let w1 = e.target.clientWidth
      if(h1<h){
        this.top = parseInt((h-h1)/2)
      }
      if(w1<w){
        this.left = parseInt((w-w1)/2)
      }
    },

    //判断滚动方向对图片进行放大缩小
    handleScroll(e){
      let direction = 'down'
      if(e.wheelDelta){
        direction = e.wheelDelta>0? 'up' : 'down'
      }
      if(e.detail){
        direction = e.detail>0? 'up' : 'down'
      }
      if(direction == 'down'){
        this.imgWidth -=1
        if(this.imgWidth<30)this.imgWidth = 30
      }else{
        this.imgWidth +=1
        if(this.imgWidth>300)this.imgWidth = 300
      }  
    },

    // 鼠标按下
    getMousedowncoor(e) {
      this.mouseInDiv = true
      this.clientX = e.clientX
      this.clientY = e.clientY
    },

    // 鼠标移动
    mousemove(e){ 
      let {clientX,clientY} = e
      //大图鼠标移动
      if(this.mouseInDiv){
        this.left += clientX - this.clientX
        this.clientX = clientX
        this.top += clientY - this.clientY
        this.clientY = clientY
        let {clientWidth,clientHeight} = this.$refs.imgTop
        let w = this.$refs.imgTitle.clientWidth
        let h = this.$refs.imgTitle.clientHeight
        if(w<=clientWidth){
          if(this.left<0)this.left = 0
          if(this.left>(clientWidth-w))this.left = clientWidth-w
        }else{
          if(this.left>0)this.left = 0
          if(this.left<(clientWidth-w))this.left = clientWidth-w
        }
        if(h<=clientHeight){   
          if(this.top<0)this.top = 0
          if(this.top>(clientHeight-h))this.top = clientHeight-h
        }else{
          if(this.top>0)this.top = 0
          if(this.top<(clientHeight-h))this.top = clientHeight-h
        }
      }

      //列表鼠标移动
      if(this.isImgMouse){
        let imgList = this.$refs.imgList
        let fw = imgList.clientWidth
        let w = this.$refs.imgListDiv.clientWidth - fw
        if(this.lastDist<=0) {
          this.lastDist = clientX
        }else{
          this.imgScrollLeft -= (clientX - this.lastDist)
          if(this.imgScrollLeft<=0)this.imgScrollLeft = 0
          if(this.imgScrollLeft>w)this.imgScrollLeft = w
          imgList.scrollLeft = this.imgScrollLeft
          this.lastDist = clientX
          this.endjl = clientX
        }
      }
    },

    //点击按钮放大缩小图片
    tapJJ(val){
      if(val=='+'){
        this.imgWidth += 10
      }else{
        this.imgWidth -= 10
      }
      if(this.imgWidth<30)this.imgWidth = 30
      if(this.imgWidth>300)this.imgWidth = 300
    },

    //图片列表鼠标按下
    imgListMousedowncoor(e){
      this.isImgMouse = true
      this.lastDist = 0
      this.statejl = e.clientX
    },

    //鼠标抬起
    imgListMouseupcoor(e){
      this.isImgMouse = false
      this.mouseInDiv = false 
    },

    //选择图片时
    tapSelImg(i){
      let num = Math.abs(this.statejl - this.endjl)
      if(this.endjl==0||num<5){
        this.imgNum = i
        // this.imgListShow = false
      }
      this.statejl = 0
      this.endjl = 0
      this.left = 0 //图片位移
      this.top = 0   //图片位移
      this.imgWidth = 70
    },

    //图片质量反馈
    imgQualityFeedback(){
      let {qualityShow,imgList,imgNum} = this
      if(!qualityShow){
        this.qualityShow = true
        this.imgUrl = imgList[imgNum]
        this.qualityFeedback = ''
      }
    },

    //点击完成时
    async tapAccomplish(){
      let {subtaskList,imgList,taskInfo,msgWarn,pdIsEmpty} = this
      let {id,taskname,totalmoney,fzr_totalmoney,totaldays} = taskInfo
      let allMoney = 0,allDay = 0
      let maxPage = subtaskList[subtaskList.length-1].endPage;
      let maxNum = imgList.length

      for(let item of subtaskList){
        if(!pdIsEmpty(item.childTaskName)){
          msgWarn('请输入子任务名称')
          return
        }
        if(item.isExist){
          msgWarn('子任务\"'+item.childTaskName+'\"名称已存在')
          return
        }
        let num = 0
        for(let obj of subtaskList){
          if(item.childTaskName==obj.childTaskName){
            num++
            if(num>=2){
              msgWarn('子任务\"'+item.childTaskName+'\"名称重复')
              return
            }
          }
        }
        if(!item.startPage||!item.endPage){
          msgWarn('请输入子任务分页')
          return
        }
        if(!item.childTaskMoney){
          msgWarn('加工酬劳不能为空或为0')
          return
        }
        if(!item.childTaskDay){
          msgWarn('加工工期不能为空或为0')
          return
        }
        allMoney += item.childTaskMoney*1
        allDay = item.childTaskDay>allDay?item.childTaskDay:allDay
      }
      if(allMoney>(totalmoney - fzr_totalmoney)){
        msgWarn('知识币分超出了发布者预付的知识币，请重新分配')
        return
      }
      if(allDay>totaldays){
        msgWarn('分配子任务最大工期超出了发布者设置的任务工期，请重新设置')
        return
      }
      if(!maxPage||maxPage<maxNum){
        msgWarn('任务分配未完成')
        return
      }
      this.LoadingIcon = Loading.service()
      let idList = []
      let then = this

      //发布失败时删除数据
      async function deleteList(idList){
        for(let id of idList){
          await DELETE(
            "post","",
            'delete_jgEntryPro(where: {id:{_eq:'+id+'}}) {affected_rows}'
          )
        }
        then.LoadingIcon.close()
      }

      //使用递归添加数据(避免报 唯一键重复)
      async function addTaskList(subtaskList,subscript){
        let {fzrName,fzrId,childTaskName,childTaskDescribe,childTaskPaging,childImgpath,
        childTaskMoney,childTaskDay} = subtaskList[subscript]
        let strRelease = "taskType:1,taskId:"+id+",taskName:\""+taskname+"\",childTaskName:\""+childTaskName+
        "\",childTaskDescribe:\""+childTaskDescribe+"\",childTaskPaging:\""+childTaskPaging+"\",childImgpath:\""+
        childImgpath+"\",childTaskMoney:"+childTaskMoney+",childTaskDay:"+childTaskDay+",status:\"0\",fzrId:"+
        fzrId+",fzrName:\""+fzrName+"\""
        let jgChildTask = await INSERT(
          "POST","",
          'insert_jgChildTask(objects:{'+ strRelease +'}) {affected_rows returning {id}}'
        )
        if(!jgChildTask.success){
          deleteList(idList)
          msgWarn('发布失败，请重试')
        }else{
          let dqxb = subscript+1
          idList.push(jgChildTask.data.insert_jgChildTask.returning[0].id)
          if(dqxb<subtaskList.length){
            addTaskList(subtaskList,dqxb)
          }else{
            then.addJgFzrAndTask(idList)
            then.addMsgData(taskInfo)
          }
        }
      }

      addTaskList(subtaskList,0)
      // for(let item of subtaskList){
        //   let {fzrName,fzrId,childTaskName,childTaskDescribe,childTaskPaging,childImgpath,childTaskMoney,childTaskDay} = item
      //   let strRelease = "taskType:1,taskId:"+id+",taskName:\""+taskname+"\",childTaskName:\""+childTaskName+
      //   "\",childTaskDescribe:\""+childTaskDescribe+"\",childTaskPaging:\""+childTaskPaging+"\",childImgpath:\""+
      //   childImgpath+"\",childTaskMoney:"+childTaskMoney+",childTaskDay:"+childTaskDay+",status:\"0\",fzrId:"+
      //   fzrId+",fzrName:\""+fzrName+"\""
      //   console.log(strRelease,213);
      //   let jgChildTask = await INSERT(
      //     "POST","",
      //     'insert_jgChildTask(objects:{'+ strRelease +'}) {affected_rows returning {id}}'
      //   )
      //   if(!jgChildTask.success){
      //     isAllAdd = false
      //     msgWarn('发布失败，请重试')
      //     break;
      //   }else{
      //     idList.push(jgChildTask.data.insert_jgChildTask.returning[0].id)
      //   }
      // }
    },

    //添加消息通知
    async addMsgData(taskInfo){
      let {id,taskname,userid,totalmoney,fzr_totalmoney,totaldays} = taskInfo
      let {fzrId,fzrName} = this.subtask
      let setData = "messageType:2,taskTime:"+new Date()*1+",taskName:\""+taskname+"\",taskType:0,taskId:"+id
      +",taskFatherId:"+id+",userId:"+userid+",userType:1,fzrId:"+fzrId+",fzrName:\""+fzrName+"\",taskTypeTow:0"
      await INSERT(
        "POST","",
        'insert_jgMessage(objects:{'+ setData +'}) {affected_rows returning {id}}'
      )
    },

    //添加任务对应负责人表数据
    async addJgFzrAndTask(idList){
      let {userInfo,taskInfo,msgWarn} = this
      let strRelease = "fzrId:"+userInfo.id+",fzrName:\""+userInfo.name+"\",jgPeperid:"+taskInfo.id+",taskname:\""+
      taskInfo.taskname+"\""
      let jgFzrAndTask = await INSERT(
        "POST","",
        'insert_jgFzrAndTask(objects:{'+ strRelease +'}) {affected_rows returning {id}}'
      )
      if(!jgFzrAndTask.success){
        for(let id of idList){
          await DELETE(
            "post","",
            'delete_jgEntryPro(where: {id:{_eq:'+id+'}}) {affected_rows}'
          )
        }
        msgWarn('发布失败，请重试')
        this.LoadingIcon.close()
      }else{
        msgWarn('发布成功','success')
        setTimeout(() => {
          this.LoadingIcon.close()
          this.$router.go(-1);
          this.$root.Hub.$emit('releaseSuccess',1)
        }, 500);
      }
    },

    //图片反馈
    async tapImgFeedback(){
      let {imgUrl,qualityFeedback,taskInfo,subtask} = this
      let {id,taskname,userid} = taskInfo
      let jgBackInform = await INSERT(
        "POST","",
        //提交反馈
        "insert_jgBackInform(objects:{imgTab:\""+imgUrl+"\",imgProblem:\""+qualityFeedback
        +"\",taskId:"+id+",taskName:\""+taskname+"\"}) {affected_rows returning {id}} "
        // //通知发布人
        // +
      )
      if(!jgBackInform.success){
        this.msgWarn('提交反馈失败，请重试')
      }else{
        let ider = jgBackInform.data.insert_jgBackInform.returning[0].id
        let msg = await INSERT(
          "POST","",
          "insert_jgMessage(objects:{messageType:6,taskTime:"+new Date()*1+",taskName:\""+taskname
            +"\",taskType:0,taskId:"+id+",taskFatherId:"+id+",userId:"+userid+",userType:1"
            +",fzrName:\""+subtask.fzrName+"\",taskTypeTow:0,backInformId:"+ider+"}) {affected_rows returning {id}}"
        )
        if(!msg.success){
          this.msgWarn('提交反馈失败，请重试')
          await DELETE(
            "post","",
            'delete_jgBackInform(where: {id:{_eq:'+ider+'}}) {affected_rows}'
          )
        }else{
          this.qualityShow = false
          this.msgWarn('提交反馈成功','success')
        }
      }
    }
  }
};
</script>

<style scoped>
.container {
  background-color: #EEEEEE;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.headClass {
  width: 1090px;
  height: 66px;
  line-height: 66px;
  text-align: left;
}
.gWbc{
  width: 1090px;
  background-color: #fff;
}
.title{
  height: 72px;
  line-height: 72px;
  text-align: center;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 3px solid #eee;
}
.basicInfo{
  padding-bottom: 40px;
  box-sizing: border-box;
}
.ente_one{
  width: 100%;
  height: 66px;
  display: flex;
  border:2px solid #eee;
  border-top: 0;
  box-sizing: border-box;
}
.ente_one>div{
  width: 50%;
  text-align: center;
  line-height: 66px;
  font-size: 14px;
  color: #000;
}
.ente_one>div:last-child{
  font-size: 18px;
  border-left: 2px solid #eee;
}
.two_right{
  width: 50%;
  border-left: 2px solid #eee;
}
.left_img_b{
  width: 100%;
  height: 500px;
  padding:10px;
  border-bottom: 2px solid #eee;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.left_img{
  width: 100%;
  flex: 1;
  background-color: #eee;
  height: 100%;
  overflow: hidden;
}
.left_img>img{
  position: relative;
}
img.imgYd{
  cursor: move;
}
.imgControl{
  width: 100%;
  height: 66px;
  border-bottom:2px solid #eee;
  display: flex;
  align-items: center;
}
.jj_btn{
  width: 22px;
  height: 22px;
  margin-left: 28px;
  border:2px solid #ddd;
  border-radius: 50%;
  text-align: center;
  line-height: 22px;
  font-size: 18px;
  cursor: pointer;
}
.jj_bum{
  width: 50px;
  text-align: center;
  margin-left: 20px;
  font-size: 16px;
  cursor: pointer;
}
.sx_btn{
  /* background-color: #EEEEEE; */
  font-size: 60px;
  font-weight: bold;
  color: #909399;
  cursor: pointer;
}
.sx_btn:hover{
  opacity: 0.8;
}
.controlList{
  width: 179px;
  height: 100%;
  /* border-left: 2px solid #eee; */
  border-right: 2px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
}
.controlZomm{
  width: 208px;
  height: 100%;
  border-right: 2px solid #eee;
  display: flex;
  align-items: center;
}
.controlText{
  flex: 1;
  height: 100%;
  padding:0 10px;
  border-right: 2px solid #eee;
  line-height: 66px;
  text-align: center;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.imgPreviewList{
  width: 100%;
  height: 90px;
  border-bottom:2px solid #eee;
  overflow: hidden;
}
.imgYcGdt{
  width:100%;
  height: 110px;
  overflow-x: auto;
}
.imgDiv{
  min-width: 100%;
  height: 90px;
  white-space:nowrap;
  display:inline-block;
}
.previewList{
  height: 90px;
  display:inline-block;
}
.imgBorder{
  border:2px solid #000;
  box-sizing: border-box;
}
.getInfo{
  width: 100%;
  padding:50px 0;
  border-bottom:2px solid #eee;
  position: relative;
}
.removeTask{
  width: 26px;
  height: 26px;
  padding:0;
  position: absolute;
  top: 10px;
  right: 20px;
}
.infoItem{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.infoItem+.infoItem{
  margin-top:20px;
}
.getInfoTs{
  padding-left: 455px;
  font-size: 12px;
  color: red;
  justify-content: start;
}
.item_title{
  width: 150px;
  text-align: right;
  font-size: 14px;
  color:#000;
}
.item_title>span{
  color:red;
}
.info_inout{
  width: 350px;
  height: 36px;
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.info_inout>span{
  margin-left: 10px;
}
.input{
  flex: 1;
  height: 36px;
  line-height: 36px;
}
.input_num{
  width: 60px;
  height: 36px;
}
.input_hx{
  width: 20px;
  height: 2px;
  background-color: #ddd;
}
.addInfoBtn{
  width: 100%;
}
.addBtn{
  padding:0 20px;
  padding-top: 10px;
  display: flex;
  justify-content:space-between;
  align-items: center;
}
.addBtn>span{
  font-size: 14px;
  color:#000;
}
.addBtn>.el-button{
  width: 26px;
  height: 26px;
  padding:0;
  margin-left:10px;
}
.addBtns{
  padding-top: 30px;
  display: flex;
  justify-content: center;
}
.keepoOut{
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0,0,0,0.2);
  top:0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.keepDiv{
  width: 854px;
  background-color: #FFFFFF;
}
.keepTitle{
  width: 100%;
  height: 66px;
  background-color:#E5E5E5 ;
  text-align: center;
  line-height: 66px;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  color: #000;
}
.keepBack{
  width: 21px;
  height: 21px;
  border:2px solid #999;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right:10px;
  cursor: pointer;
}
.keepBack:hover{
  opacity: 0.8;
}
.keepBack>i{
  font-size: 30px;
  color:#999;
}
.keepinput{
  width: 100%;
  padding:70px 0;
}
.areaStyle{
  align-items:flex-start;
  height: 200px;
}
.areaStyle1>.item_title{ 
  width: 80px;
}
.areaStyle>.item_title{ 
  width: 80px;
}
.areaStyle>.info_inout{
  height: 200px;
  align-items: flex-start;
}
.areaStyle>.input{
  height: 200px;
}
.areaStyle>>>.el-textarea__inner{
  height: 200px;
}
</style>
